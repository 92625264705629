import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card, Segment, Grid, Icon } from "semantic-ui-react"
import GoldenMilkImg from "../images/7.jpg"
import GoldenMilkImg2 from "../images/8.jpg"
// import GoldenMilkImg3 from "../images/6.jpeg"

const SinglePostPage = () => {
  return (
    <Layout>
      <SEO title="Roasted Chicken | My Food Book " />

      <div style={{ margin: "100px 0" }}>
        <Card
          className="single-post-page-card"
          style={{ width: "90vw", maxWidth: "900px" }}
          image={GoldenMilkImg2}
          centered
          header={<h1 style={{ textAlign: "center" }}>Roasted Chicken</h1>}
          meta={
            <div>
              <Segment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "grey",
                  }}
                >
                  <div>
                    By : <b>Nibedita Satpathy</b>
                  </div>
                  <div>
                    Published On: <b>05 May, 2020</b>
                  </div>
                </div>
              </Segment>
              <Segment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "grey",
                  }}
                >
                  <div>
                    Time to prepare : <b>80 Min</b>
                  </div>
                  <div>
                    Servings : <b> 2 Persons</b>
                  </div>
                </div>
              </Segment>
              <br />
            </div>
          }
          description={
            <div>
              <Segment>
                <h2>Ingrediants</h2>
                <div>
                  1. whole chicken (giblets removed).
                  <br />
                  2. salt as per taste
                  <br />
                  3. Olive oil 2 tsp
                  <br />
                  4. rosemarry 3-4 strands.
                  <br />
                  5. 1 tbsp lemon juice.
                  <br />
                  6. mix of all indian spices (1/3 tsp Turmeric powder,1/2 tsp
                  red chilly powder,1/2 cumin powder,1/2 tsp coriander powder
                  and 1/2 tsp od chicken masala ) <br />
                  7. Garlic paste 1/2 tsp <br />
                  8. Ginger paste 1/3 tsp
                  <br />
                </div>
              </Segment>
              <Segment style={{ fontSize: "18px" }}>
                <h2>Procedure</h2>
                <p>
                  1. Pat dry the chicken <br />
                  2. Season the chicken with salt and lemon juice and olive and
                  leave it for 10-15 minutes
                  <br />
                  3. Preheat the oven to 400 f/200 c .<br />
                  4. mariante the chciekn with rest of the spices and place the
                  chicken in the iron skillet .<br />
                  5. roast it for 1 hour until juices ru clear.
                  <br />
                  6.serve chicken with roasted vegetables or steamy rice.
                  <br />
                </p>
              </Segment>
              <Segment>
                <h2>Dish Gallery</h2>
                <Grid
                  columns={3}
                  centered
                  padded="vertically"
                  celled="internally"
                  stackable
                >
                  <Grid.Row>
                    <Grid.Column fluid>
                      <img
                        src={GoldenMilkImg}
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src={GoldenMilkImg2}
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment>
                <h2>Share</h2>
                <div>
                  <Icon
                    circular
                    size="large"
                    color="blue"
                    name="facebook square"
                  />
                  <Icon circular size="large" color="violet" name="linkedin" />
                  <Icon circular size="large" color="teal" name="twitter" />
                  <Icon circular size="large" color="green" name="whatsapp" />
                </div>
              </Segment>
            </div>
          }
        />
      </div>
    </Layout>
  )
}

export default SinglePostPage
